import React, { FC } from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";

type Entry = {
  title?: string;
  content: string;
  uri: string;
  date: string;
  featuredImage?: { node: { gatsbyImage: ImageDataLike; altText: string } };
};

const Entry: FC<{ entry: Entry }> = ({ entry }) => {
  const image =
    entry.featuredImage?.node?.gatsbyImage &&
    getImage(entry.featuredImage?.node?.gatsbyImage);

  return (
    <li>
      <article className="h-entry entry">
        {entry.title && entry.title !== "Utan namn" ? (
          <>
            <h2 className="text-xl font-bold p-name">
              <Link to={entry.uri}>{entry.title}</Link>
            </h2>

            <Link to={entry.uri} className="u-url">
              <time className="dt-published" itemProp="datePublished">
                {entry.date}
              </time>
            </Link>
          </>
        ) : (
          <>
            <h2 className="font-bold">
              <Link to={entry.uri} className="u-url">
                <time className="dt-published" itemProp="datePublished">
                  {entry.date}
                </time>
              </Link>
            </h2>
          </>
        )}

        {image && (
          <Link to={entry.uri} className="block mt-2">
            <GatsbyImage
              image={image}
              alt={entry.featuredImage?.node.altText ?? ""}
            />
          </Link>
        )}

        <section
          className="e-content entry-body"
          dangerouslySetInnerHTML={{ __html: entry.content }}
        ></section>
      </article>
    </li>
  );
};

export default Entry;
