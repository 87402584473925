import * as React from "react";
import { graphql, Link, PageProps } from "gatsby";
import Entry from "../components/entry";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { ImageDataLike } from "gatsby-plugin-image";

type DataProps = {
  allWpPost: {
    nodes: {
      title: string;
      excerpt: string;
      uri: string;
      date: string;
      content: string;
      featuredImage?: { node: { gatsbyImage: ImageDataLike; altText: string } };
    }[];
  };
};

// markup
const IndexPage = ({ data }: PageProps<DataProps>) => {
  return (
    <Layout isHome={true}>
      <Seo title={"Hemma"} />

      <header>
        <div className="my-4 text-4xl">👋</div>

        <div>
          <h1 className="text-xl font-bold">Hej, jag heter Johan.</h1>
          <p className="my-4">
            Jag bygger webbsidor, appar och allsköns digitala ting.{` `}
            <br className="hidden md:block" />
            Just nu gör jag det hos{` `}
            <a href="https://funkismultimedia.se">Funkis Multimedia</a>.
          </p>
        </div>
      </header>

      <ul className="h-feed space-y-32 mt-24 pt-24 md:mt-32 md:pt-32 border-t dark:border-slate-700">
        {data.allWpPost.nodes.map((node) => (
          <Entry key={node.uri} entry={node} />
        ))}
      </ul>
    </Layout>
  );
};

//highlight-start
export const pageQuery = graphql`
  query IndexPage {
    allWpPost(sort: { fields: [date], order: DESC }, limit: 10) {
      nodes {
        title
        excerpt
        uri
        content
        date(formatString: "dddd, Do MMM", locale: "sv-SE")
        featuredImage {
          node {
            gatsbyImage(width: 1200, placeholder: BLURRED)
            altText
          }
        }
      }
    }
  }
`;

export default IndexPage;
